import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Probably one of the most famous MTB spots in the alps since Elmar Moser
published his bike guides in the 90s. Already because of the landscape Garda
is worth a trip. Ending the descent at the lake, riding along the promenade of
Riva del Garda, sitting down and enjoy a good italian coffe and the best
icecream. What else do you want?`}</p>
    <br />
    <p>{`Ah yes, trails! Well the trails directly around Riva and Torbole are more on
the technical side. Fans of rocks, especially loose rocks will love it. You
have long descents, for example from the Monte Altissimo 1900vm down to the
lake with famous tracks like the 601 on your way.`}</p>
    <br />
    <p>{`But also easier trails were built, such as the Navene or Naranch trail.
Most of those trails are accessible by shuttles.`}</p>
    <br />
    <p>{`Leaving the lake behind and moving to the valleys around - Rovereto for
example - the trails change their character, and you will also find some
less known trails.`}</p>
    <TrailguidePlugin content="lat=45.8780&lng=10.9189&coverage=10" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=12,45.8780,10.9189"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`Coast Trail`}</h4>
    <p>{`The coast trail offers one of the best views on the northern part of the lake.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2841"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Val del Diaol (Skull Trail)`}</h4>
    <p>{`Rocks everywhere. Here in a compact version on the Skull Trail (Val del Diaol).
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2861"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Naranch`}</h4>
    <p>{`But you will also find some dirt. The Naranch trail is less technical with roots and dirt.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2860"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Navene Trail`}</h4>
    <p>{`Also the Navene Trail is less hard with some flowy and fast sections.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2858"
      }}>{`here`}</a></p>
    <Image src="destinations/garda_2.jpg" mdxType="Image" />
    <br />
    <h4>{`Navene Trail`}</h4>
    <p>{`But also there you always have to be prepared for loose gravel.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2858"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Coast Trail`}</h4>
    <p>{`Nice rock section in the lower part.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2841"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Pianaura`}</h4>
    <p>{`Close to Arco you find less known trails that are not accessible with a shuttle.
Find the trail `}<a parentName="p" {...{
        "href": "href='https://trailguide.net/2867"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Malga Valestre`}</h4>
    <p>{`Offcamber rockslab. The limestones are incredible slippery when they get wet.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2866"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/garda_7.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      